import { CacheService } from 'app/shared/services/cache/cache.service';

import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

import { info } from './info';

@Component({
	selector: 'deferral-modal-page',
	template: `
		<div class="relative border flex flex-col h-screen">
			<div class="mx-auto">
				<div class="p-5">
					<div class="rounded-md bg-yellow-50 p-4">
						<div class="flex">
							<div class="flex-shrink-0">
								<svg
									class="h-10 w-10 text-yellow-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
							<div class="ml-3">
								<div
									class="text-center text-2xl gt-xs:text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
								>
									Registration Needs Attention
								</div>
							</div>
						</div>
					</div>
				</div>
				<p class="p-5 mt-4 max-w-3xl mx-auto text-left text-base gt-xs:text-xl text-black">
					Sorry, your verification test did not meet the company requirements to rent this
					vessel. Please contact the business by phone or email if you would like further
					explanation.
				</p>
				<address
					class="p-5 mt-4 max-w-3xl mx-auto text-center text-base gt-xs:text-xl text-black"
				>
					{{ company.company_name }}<br />
					<div class="mt-5">
						<a
							class="inline-flex mr-5 items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-cyan-700 bg-cyan-100 hover:bg-cyan-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
							[href]="'tel:' + company.contact.main_phone"
							>{{ company.contact.main_phone }}</a
						>
						<a
							class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-cyan-700 bg-cyan-100 hover:bg-cyan-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
							[href]="'mailto:' + company.email"
							>{{ company.email }}</a
						>
					</div>
				</address>
			</div>
		</div>
	`,
})
export class DeferralModalPage implements OnInit {
	public company = this.cache.get('company');
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		private modalController: ModalController,
		public loadingController: LoadingController,
		private readonly cache: CacheService
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	ngOnInit() {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async closeModal() {
		await this.modalController.dismiss();
	}
}
