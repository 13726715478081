import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from '../cache/cache.service';

@Injectable({ providedIn: 'root' })
export class AuthDataService {
	private servicesUrl: string = environment.authEndPoint;
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private readonly http: HttpClient, private readonly cacheService: CacheService) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - verifyAccount
	 **-------------------------------------------------------------------------------------
	 */
	public verifyAccount(email: string): Observable<any> {
		const url = `${this.servicesUrl}/verify-account`;
		return this.http.post(url, { email }, {});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - verifyAccount
	 **-------------------------------------------------------------------------------------
	 */
	public confirmAccount(token: string): Observable<any> {
		const url = `${this.servicesUrl}/confirm-account`;
		return this.http.post(url, { token }, {});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - verifyAccount
	 **-------------------------------------------------------------------------------------
	 */
	public assumeAccount(companyId: number): Observable<any> {
		const url = `${this.servicesUrl}/mgmt/assume-account`;
		const accessToken = this.cacheService.get('accessToken');
		const idToken = this.cacheService.get('idToken');
		return this.http.post(
			url,
			{ companyId },
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
					idToken: `${idToken.toString()}`,
					'x-api-key': environment.apiKey,
				},
			}
		);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - setAccountPassword
	 **-------------------------------------------------------------------------------------
	 */
	public setAccountPassword(token: string, password: string): Observable<any> {
		const url = `${this.servicesUrl}/set-account-password`;
		return this.http.post(url, { token, password }, {});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - validateBusinessLogin
	 **-------------------------------------------------------------------------------------
	 */
	public validateBusinessLogin(username: string, password: string): Observable<any> {
		const url = `${this.servicesUrl}/validate-business-login`;
		return this.http.post(url, { username, password }, {});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - validateBusinessLogin
	 **-------------------------------------------------------------------------------------
	 */
	public validateAdminLogin(username: string, password: string): Observable<any> {
		const url = `${this.servicesUrl}/validate-admin-login`;
		return this.http.post(url, { username, password }, {});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - resetCustomerPassword
	 **-------------------------------------------------------------------------------------
	 */
	public forgotBusinessPassword(email: string): Observable<any> {
		const url = `${this.servicesUrl}/forgot-business-password`;
		return this.http.post(url, { email }, {});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - resetBusinessPassword
	 **-------------------------------------------------------------------------------------
	 */
	public resetBusinessPassword(token: string, password: string): Observable<any> {
		const url = `${this.servicesUrl}/reset-business-password`;
		return this.http.post(url, { token, password }, {});
	}
}
