import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from '../cache/cache.service';

@Injectable({ providedIn: 'root' })
export class PaymentService {
	private servicesUrl: string = environment.adminEndPoint;
	private paymentsUrl: string = environment.paymentsEndPoint;

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private readonly http: HttpClient, private readonly cacheService: CacheService) {}

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - submitPayment
	 **-------------------------------------------------------------------------------------
	 */
	public submitPayment(data): Observable<any> {
		const url = `${this.servicesUrl}/payments/process-payment`;
		return this.http.post(url, { ...data });
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getPayments
	 **-------------------------------------------------------------------------------------
	 */
	public getPayments(invoiceId): Observable<any> {
		const url = `${this.servicesUrl}/payments/${invoiceId}/payments`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getPayments
	 **-------------------------------------------------------------------------------------
	 */
	public getAllPayments(reservationId): Observable<any> {
		const url = `${this.servicesUrl}/payments/${reservationId}/all`;
		return this.http.get(url);
	}

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCreditCard
	 **-------------------------------------------------------------------------------------
	 */
	public createCharge(data): Observable<any> {
		const url = `${this.paymentsUrl}/charge/reservation`;
		return this.http.post(url, { ...data });
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCreditCard
	 **-------------------------------------------------------------------------------------
	 */
	public saveCreditCard(data): Observable<any> {
		const url = `${this.paymentsUrl}/verify-card`;
		return this.http.post(url, { ...data });
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCreditCard
	 **-------------------------------------------------------------------------------------
	 */
	public getRevenueTotal(filter): Observable<any> {
		const url = `${this.paymentsUrl}/analytics/revenue-total?filter=${filter}`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCreditCard
	 **-------------------------------------------------------------------------------------
	 */
	public getAveragePrice(filter): Observable<any> {
		const url = `${this.paymentsUrl}/analytics/average-price?filter=${filter}`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCreditCard
	 **-------------------------------------------------------------------------------------
	 */
	public getFees({ total, postalCode, companyId, asObject }): Observable<any> {
		const url = `${this.paymentsUrl}/fees`;
		return this.http.post(url, { total, postalCode, companyId, asObject });
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCreditCard
	 **-------------------------------------------------------------------------------------
	 */
	public validateTaxFee({ postalCode }): Observable<any> {
		const url = `${this.paymentsUrl}/fees/validae-tax-fee/${postalCode}`;
		return this.http.get(url);
	}
}
