import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';

@Directive({
	selector: '[licenseCode]',
})
export class LicenseCodeDirective {
	@Input('country')
	protected hyphenKeyCode: number = 189;
	protected backSpaceKeyCode: number = 8;
	constructor(protected el: ElementRef, protected ngControl: NgControl) {}

	@HostListener('keyup', ['$event'])
	protected onkeyup(event): void {
		const keyCode: number = event.keyCode;
		let value: string = this.ngControl.control.value;
		this.ngControl.control.setValue(value.replace(/[^A-Z0-9]/gi, ''));
	}
}
