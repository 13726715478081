import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

import { info } from './info';

@Component({
	selector: 'save-modal-page',
	template: `
		<div class="relative border flex flex-col h-screen">
			<div class="m-auto">
				<ion-icon
					(click)="closeModal()"
					size="large"
					class="absolute top-3 right-5 cursor-pointer"
					name="close-sharp"
				></ion-icon>
				<div
					class="pt-5 text-center text-2xl gt-xs:text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
				>
					Lorem ipsum
				</div>
				<p
					class="px-10 mt-4 max-w-3xl mx-auto text-left text-base gt-xs:text-xl text-gray-500"
				>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					Nunc vehicula dolor feugiat, tempus sapien at, vehicula
					tortor. Vestibulum in eros a dolor efficitur tincidunt sit
					amet ut ante. Fusce porta nibh ante, sit amet feugiat leo
					ornare et. Nam quis turpis arcu. Suspendisse varius feugiat
					arcu non consectetur.
				</p>
				<p
					class="px-10 mt-4 max-w-3xl mx-auto text-left text-base gt-xs:text-xl text-gray-500"
				>
					Curabitur suscipit congue ex, a mattis erat sollicitudin et.
					Aenean felis massa, tristique at dapibus at, varius id mi.
					In ut eleifend diam. Aliquam tristique posuere dolor ut
					egestas. Maecenas eu facilisis risus, ac blandit nisl.
					Pellentesque ullamcorper quam ut ex euismod, et consequat
					nibh pulvinar. Donec nec ornare diam, id finibus lectus. Sed
					erat mi, blandit ut pharetra vitae, ornare eget lacus.
					Integer pulvinar sagittis convallis.
				</p>

				<div
					class="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
				>
					<h1 class="text-center vquip-font-heavy text-2xl border-b">
						<span
							class="text-gray-400 font-extrabold text-3xl tracking-tight"
							>V</span
						>
						<span
							class="text-transparent  bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-600"
						>
							<span
								class=" tracking-tight text-3xl font-extrabold"
								>Q</span
							>
							<span
								class=" tracking-tight text-3xl font-extrabold"
								>D</span
							>
							<span class=" tracking-tight font-semibold"
								>ealer</span
							>
						</span>
					</h1>
				</div>
			</div>
		</div>
	`,
})
export class MoreInfoModalPage implements OnInit {
	public infoItem;
	public type;
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		private modalController: ModalController,
		public loadingController: LoadingController
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	ngOnInit() {
		this.infoItem = info[this.type];
		console.log(this.infoItem);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async closeModal() {
		await this.modalController.dismiss();
	}
}
