import { AlertModalPage } from './alert-modal/alert-modal.component';
import { AlertComponent } from './alert/alert.component';
import { CompleteModalPage } from './complete-modal/complete-modal.component';
import { DeferralModalPage } from './deferral-modal/deferral-modal.component';
import { DocumentModalPage } from './document-modal/document-modal.component';
import { Footer } from './footer/footer.component';
import { FraudModalPage } from './fraud-modal/fraud-modal.component';
import { Header } from './header/header.component';
import { ImageLightboxPage } from './image-lightbox/image-lightbox.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { PaymentFormModalComponent } from './payment-form-modal/payment-form-modal.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { FraudModalComponent, SignatureModalPage } from './signature/signature-modal.component';
import { SignaturePadComponent } from './signature/signature-pad.component';
import { StartDetailModalPage } from './start-detail-modal/start-detail-modal.component';

export const components = [
	Footer,
	Header,
	PaymentFormComponent,
	InvoiceComponent,
	SignatureModalPage,
	SignaturePadComponent,
	DeferralModalPage,
	FraudModalComponent,
	AlertComponent,
	StartDetailModalPage,
	CompleteModalPage,
	PaymentFormModalComponent,
	AlertModalPage,
	FraudModalPage,
	DocumentModalPage,
	ImageLightboxPage,
];
export * from './footer/footer.component';
export * from './header/header.component';
export * from './payment-form/payment-form.component';
export * from './invoice/invoice.component';
export * from './signature/signature-modal.component';
export * from './signature/signature-pad.component';
export * from './deferral-modal/deferral-modal.component';
export * from './alert/alert.component';
export * from './start-detail-modal/start-detail-modal.component';
export * from './complete-modal/complete-modal.component';
export * from './payment-form-modal/payment-form-modal.component';
export * from './alert-modal/alert-modal.component';
export * from './fraud-modal/fraud-modal.component';
export * from './document-modal/document-modal.component';
export * from './image-lightbox/image-lightbox.component';
