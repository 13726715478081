import { CacheService } from 'app/shared/services';
import { environment } from 'environments/environment';
import { NgEventBus } from 'ng-event-bus';

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

@Component({
	selector: 'save-signature',
	template: `
		<div
			[class.hidden]="!isLoading"
			class="w-full h-full bg-gray-500 absolute opacity-50 z-50"
		></div>
		<div style="overflow:scroll; height:70vh">
			<div class="pt-5">
				<p
					*ngIf="message.length"
					[innerHTML]="message"
					class="text-center text-xl text-gray-500 leading-8"
				></p>
				<p class="text-center text-xl text-gray-500 leading-8">
					Please draw or type your signature Below
				</p>
				<div class="px-5">
					<div class="border-b border-gray-200">
						<nav class="-mb-px flex space-x-8" aria-label="Tabs">
							<a
								(click)="changeTab('draw')"
								class="cursor-pointer border-transparent  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-base"
								[class.border-cyan-500]="tab === 'draw'"
								aria-current="page"
							>
								Draw
							</a>
							<a
								[class.border-cyan-500]="tab === 'type'"
								(click)="changeTab('type')"
								class="cursor-pointer border-transparent  hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-base"
							>
								Type
							</a>
						</nav>
					</div>
				</div>
			</div>
			<div [class.hidden]="tab !== 'draw'" #container class="flex justify-center  relative">
				<ng-signature-pad
					[name]="name"
					[httpRequestHeaders]="httpRequestHeaders"
					[filePath]="filePath"
					[disabledDone]="true"
					(done)="done()"
					(signatureStarted)="onSignatureStarted($event)"
					(saveSignature)="saveSignature($event)"
					height="250"
					[width]="container.offsetWidth - 50"
				></ng-signature-pad>
			</div>
			<div [class.hidden]="tab !== 'type'" class="flex  justify-center  relative">
				<input
					#siginput
					placeholder="Signature"
					(keyup)="onTypeSignatureStarted()"
					type="text"
					class="text-6xl lt-md:text-3xl w-10/12 outline-none text-center  focus:outline-none  focus:ring-white focus:border-black border-0 border-b-4 focus:border-b-4"
					style=""
				/>
			</div>
			<canvas class="hidden" id="sigcanvas" [width]="500" height="100"></canvas>
			<div [class.hidden]="tab !== 'type'" class="flex  justify-center  relative">
				<div class="text-center w-full  text-lg text-cyan-500 font-semibold cursor-pointer">
					<a (click)="siginput.value = ''; canSubmit = false">Clear Signature</a>
				</div>
			</div>
		</div>
		<div class="flex justify-end p-5 gap-4">
			<button
				(click)="closeModal()"
				class="text-xl lt-md:text-base block py-1 px-6 rounded shadow bg-gradient-to-r bg-black text-white"
			>
				Cancel
			</button>
			<button
				[disabled]="!canSubmit"
				(click)="completed()"
				class="text-xl lt-md:text-base block whitespace-nowrap  py-1 px-6 rounded shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
			>
				{{ btnText }}
			</button>
		</div>
	`,
	styles: [
		`
			input[type='text'] {
				font-family: 'Dancing Script', cursive;
			}
			input[type='text']:focus {
				outline: none !important;
			}

			::-webkit-input-placeholder {
				text-align: center;
			}

			:-moz-placeholder {
				/* Firefox 18- */
				text-align: center;
			}

			::-moz-placeholder {
				/* Firefox 19+ */
				text-align: center;
			}

			:-ms-input-placeholder {
				text-align: center;
			}
		`,
	],
})
export class SignatureModalPage implements OnInit {
	@Input() filePath: string = '';
	@Input() name: string = '';
	@Input() message: string = '';
	@Input() btnText: string = 'Done';
	@ViewChild('siginput') siginput;
	public tab = 'draw';
	public isLoading: boolean = true;
	public isDone: boolean = false;
	public canSubmit: boolean = false;
	public httpRequestHeaders = {
		'x-env-name': environment.env,
		'x-api-key': environment.apiKey,
	};

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		private modalController: ModalController,
		public loadingController: LoadingController,
		private readonly eventBus: NgEventBus
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	async ngOnInit() {
		const modal = await this.modalController.create({
			component: FraudModalComponent,
			backdropDismiss: false,
		});
		await modal.present();
		const { data } = await modal.onDidDismiss();
		this.isLoading = false;
		if (data.type == 'no') {
			await modal.dismiss();
			await this.modalController.dismiss({
				type: 'cancel',
			});
			return;
		}
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - onTypeSignatureStarted
	 **-------------------------------------------------------------------------------------
	 */
	public onTypeSignatureStarted(): void {
		this.canSubmit = this.siginput.nativeElement.value.length > 0;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - onSignatureStarted
	 **-------------------------------------------------------------------------------------
	 */
	public onSignatureStarted({ started }): void {
		this.canSubmit = started;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async closeModal() {
		await this.modalController.dismiss({
			type: 'cancel',
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - foo
	 **-------------------------------------------------------------------------------------
	 */
	public done(): void {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - foo
	 **-------------------------------------------------------------------------------------
	 */
	public async saveSignature($event) {
		await this.modalController.dismiss({
			type: 'complete',
			signaturePath: $event,
		});
	}

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - changeTab
	 **-------------------------------------------------------------------------------------
	 */
	public changeTab(tab): void {
		this.tab = tab;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - completed
	 **-------------------------------------------------------------------------------------
	 */
	public async completed() {
		this.isLoading = true;
		this.eventBus.cast('signatureDone', {
			type: this.tab,
			signature: this.siginput.nativeElement.value,
		});
	}
}

@Component({
	selector: 'app-fraud-warning-modal',
	template: `
		<div class="text-sm lt-md:text-sm pt-5 px-5 relative" style="overflow:scroll; height:100vh">
			<span class="font-bold text-base lt-md:text-base"
				>CONSENT TO USE ELECTRONIC SIGNATURE</span
			>
			<br />
			<br />
			<app-alert
				*ngIf="error"
				message="Please check that you can read and understand English and consent to use electronic signature"
			></app-alert>

			<p class="mt-4 max-w-3xl mx-auto  text-base gt-xs:text-xl text-black">
				<label>
					<input (click)="checkCheckBoxes()" #agree type="checkbox" value="1" /> I can
					read and understand English
				</label>
			</p>
			<p class="mt-4 max-w-3xl mx-auto  text-base gt-xs:text-xl text-black">
				<label>
					<input (click)="checkCheckBoxes()" #consent type="checkbox" value="1" />
					Consent to use electronic signature
				</label>
			</p>
			<div style="overflow:scroll; height:55vh">
				By submitting this signature, you hereby consent, acknowledge and agree that your
				electronic signature is intended to authenticate and to execute this agreement as
				well as any other notices, acknowledgements or other documents that you sign in
				connection with the... transactions between us, and that each electronic signature
				will have the same force and effect as an original signature on paper . You have the
				right not to check the box and request that you manually sign a paper copy instead.
				By submitting this signature, you are waiving that right. You may, following your
				execution of the document(s) upon written request to us, obtain a single paper copy
				of the electronic record(s). No fee will be charged for any such copies, and no
				special hardware or software is required to view the electronic or digital copies.
				Your agreement and consent to use an electronic signature with us for any documents
				will continue until such time as you notify us in writing that you no longer wish to
				use an electronic signature. There is no penalty for withdrawing your consent. You
				should always make sure that we have a current email address in order to contact you
				regarding any changes, if necessary. Without limiting the foregoing, your electronic
				signature evidences your agreement to the terms of the rental agreement, waiver,
				acknowledgements and completion of logged safety briefing steps. If you need to
				update your email address or would like a paper copy of the electronic records,
				please contact <br /><br />
				{{ company.company_name }}<br />
				{{ company.email }}
			</div>
			<div class="flex justify-end p-5 gap-4 absolute bottom-0 right-0">
				<button
					(click)="closeModal('no')"
					class="text-xl lt-md:text-base block py-1 px-6 rounded shadow bg-gradient-to-r bg-black text-white"
				>
					Cancel
				</button>
				<button
					[disabled]="btnDisabled"
					(click)="closeModal('yes')"
					class="text-xl lt-md:text-base block whitespace-nowrap  py-1 px-6 rounded shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
				>
					I Agree
				</button>
			</div>
		</div>
	`,
})
export class FraudModalComponent {
	public company = this.cache.get('company');
	public btnDisabled: boolean = true;
	public error: boolean = false;

	@ViewChild('agree') agree: ElementRef;
	@ViewChild('consent') consent: ElementRef;

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private modalController: ModalController, private readonly cache: CacheService) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - checkCheckBoxes
	 **-------------------------------------------------------------------------------------
	 */
	public checkCheckBoxes(): void {
		if (this.agree.nativeElement.checked && this.consent.nativeElement.checked) {
			this.btnDisabled = false;
			return;
		}
		this.btnDisabled = true;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async closeModal(type) {
		if (type == 'no') {
			await this.modalController.dismiss({
				type,
			});
			return;
		}

		if (!this.agree.nativeElement.checked || !this.consent.nativeElement.checked) {
			this.error = true;
			return;
		}

		await this.modalController.dismiss({
			type,
		});
	}
}
