import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from '../services/cache/cache.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private cacheService: CacheService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const accessToken = this.cacheService.get('accessToken');
		const idToken = this.cacheService.get('idToken');

		if (accessToken && idToken) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${accessToken}`,
					idToken: `${idToken.toString()}`,
					'x-api-key': environment.apiKey,
				},
			});
		}

		return next.handle(request);
	}
}
