import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: 'launch',
		loadChildren: () => import('./pages/404/404.module').then(m => m.NotFoundModule),
	},

	{
		path: 'manifest',
		loadChildren: () => import('./pages/404/404.module').then(m => m.NotFoundModule),
	},
	{
		path: 'payment/:token',
		loadChildren: () =>
			import('./pages/adhoc-payment/adhoc-payment.module').then(
				m => m.AdHocPaymentPageModule
			),
	},
	{
		path: 'timeout',
		loadChildren: () => import('./pages/timeout/timeout.module').then(m => m.TimeOutModule),
	},
	{
		path: 'end-reservation',
		loadChildren: () => import('./pages/404/404.module').then(m => m.NotFoundModule),
	},
	{
		path: 'registration',
		loadChildren: () =>
			import('./pages/registration/registration.module').then(m => m.RegistrationPageModule),
	},
	{
		path: 'risk-assessment',
		loadChildren: () => import('./pages/404/404.module').then(m => m.NotFoundModule),
	},
	{
		path: '404',
		loadChildren: () => import('./pages/404/404.module').then(m => m.NotFoundModule),
	},
	{
		path: 'terms',
		loadChildren: () => import('./pages/404/404.module').then(m => m.NotFoundModule),
	},
	{
		path: '',
		loadChildren: () => import('./pages/404/404.module').then(m => m.NotFoundModule),
	},
	{
		path: '',
		redirectTo: '',
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
