import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			//retry(1),
			catchError(err => {
				console.log('in interceptor', err);
				let error = err?.error?.message || err?.statusText;
				if (Array.isArray(error)) error.join('<br />');
				Sentry.captureException(
					error, 
					{
						level: 'fatal',
						user: {},
						extra: {}
					}
				);
				return throwError(error);
			})
		);
	}
}
