import '@themesberg/flowbite';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	Sentry.init({
		dsn: 'https://8008b2140c3b412f902903e98894de76@o1143819.ingest.sentry.io/6213067',
		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: ['https://api.vquiprentals.com'],
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
			new Sentry.Replay(),
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.5,
		replaysOnErrorSampleRate: 1.0,
		environment: 'prod',
		attachStacktrace: true,
		release: '1.0.0',
	});
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));
