import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from '../cache/cache.service';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class FeaturesDataService extends BaseService {
	private featuresServiceUrl: string = environment.featuresEndPoint;

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private readonly http: HttpClient, private readonly cacheService: CacheService) {
		super();
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getCompanyFeatures
	 * *-------------------------------------------------------------------------------------
	 */
	public getCompanyFeatures(companyId: number): Observable<any> {
		const url = `${this.featuresServiceUrl}/public/company/${companyId}`;
		const headers = {
			'x-api-key': environment.apiKey,
		};
		return this.http
			.get(url, { headers })
			.pipe(this.handleRetry(url, {}), catchError(this.handleError));
	}

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - createCompanyFeatures
	 * *-------------------------------------------------------------------------------------
	 */
	public createCompanyFeatures(companyId: number): Observable<any> {
		const url = `${this.featuresServiceUrl}/company/${companyId}`;
		return this.http.post(url, null);
	}

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - updateCompanyFeatures
	 * *-------------------------------------------------------------------------------------
	 */
	public updateCompanyFeatures(companyId: number, companyFeaturesData: any): Observable<any> {
		const url = `${this.featuresServiceUrl}/company/${companyId}`;
		return this.http.put(url, companyFeaturesData);
	}
}
