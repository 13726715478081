import { BasePage } from 'app/pages/base.page';
import { NgEventBus } from 'ng-event-bus';

import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';

@Component({
	selector: 'app-invoice',
	templateUrl: 'invoice.component.html',
})
export class InvoiceComponent implements OnInit {
	@Input() planNumber: number = 0;
	@Input() planName: string = '';
	@Input() selectedPlan: any;
	@Input() complete: boolean = false;
	@Input() plans: any[] = [];
	@Input() invoiceLineItems: any[] = [];
	@Input() transactionFee: number = 0;
	@Input() totalPrice: number = 0;
	@Input() payTotalPayment: boolean = false;
	@Input() allowRemove: boolean = false;
	@Output() onRemove: EventEmitter<any> = new EventEmitter();
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor() {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	async ngOnInit() {
		if (!this.planNumber) this.planNumber = -1;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - removeItem
	 **-------------------------------------------------------------------------------------
	 */
	public removeItem(item) {
		this.onRemove.emit(item.invoice_line_item_id);
	}
}
