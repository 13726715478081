import { ErrorInterceptor } from './error.interceptor';
import { AppHttpInterceptor } from './http.interceptor';
import { JwtInterceptor } from './jwt.interceptor';
import { MonitorInterceptor } from './monitor.interceptor';

export const interceptors = [
	AppHttpInterceptor,
	JwtInterceptor,
	MonitorInterceptor,
	ErrorInterceptor,
];
export * from './http.interceptor';
export * from './jwt.interceptor';
export * from './monitor.interceptor';
export * from './error.interceptor';
