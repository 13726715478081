export const NON_INSURABLE_PRODUCT_TYPES = [
	'Activity',
	'High Performance (Not Insurable)',
	'Tour',
	'Snowmobile',
	'Side By Side (SSV)',
	'Trike/Spyder (3 wheeler)',
	'ATV',
	'Parasail',
	'Cabin',
	'House',
];

export const REGEX_PATTERNS = {
	NAME: /^[A-Z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*$/,
	PHONE: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
	ADDRESS: /^[A-Za-z0-9\s,.'-]*$/,
	CITY: /^[A-Za-z\s,'-]*$/,
	POSTAL: /^[A-Za-z0-9,-]*$/,
	DOB: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
	NO_LEADING_SPACES: /^\S[A-Za-z0-9-'`~. ]*$/,
	CARD_NUMBER: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
	CVV: /^[0-9]{3,4}$/,
};
