import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from '../cache/cache.service';

@Injectable({ providedIn: 'root' })
export class CustomerService {
	private servicesUrl: string = environment.customersEndPoint;

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private readonly http: HttpClient, private readonly cacheService: CacheService) {}

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getCustomer
	 **-------------------------------------------------------------------------------------
	 */
	public getCustomer(customerId): Observable<any> {
		const url = `${this.servicesUrl}/public/${customerId}`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getCustomer
	 **-------------------------------------------------------------------------------------
	 */
	public getCustomers(): Observable<any> {
		const url = `${this.servicesUrl}/list`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - listCustomerPaymentMethods
	 **-------------------------------------------------------------------------------------
	 */
	public listCustomerPaymentMethods({ customerId }): Observable<any> {
		const url = `${this.servicesUrl}/${customerId}/payment-methods`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - listCustomerPaymentMethods
	 **-------------------------------------------------------------------------------------
	 */
	public getCustomerReservations({ customerId }): Observable<any> {
		const url = `${this.servicesUrl}/${customerId}/reservations`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - listCustomerPaymentMethods
	 **-------------------------------------------------------------------------------------
	 */
	public getCustomerInvoices({ customerId }): Observable<any> {
		const url = `${this.servicesUrl}/customers/${customerId}/invoices`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - updateCustomerPaymentMethods
	 **-------------------------------------------------------------------------------------
	 */
	public updateCustomerPaymentMethods({ customerId, token, paymentId }): Observable<any> {
		const url = `${this.servicesUrl}/customers/${customerId}/payment-methods`;
		return this.http.post(url, { token, paymentId });
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCustomerNote
	 **-------------------------------------------------------------------------------------
	 */
	public saveCustomerNote({ customerId, note }): Observable<any> {
		const url = `${this.servicesUrl}/customers/${customerId}/update-notes`;
		return this.http.post(url, { note });
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCustomerNote
	 **-------------------------------------------------------------------------------------
	 */
	public saveCustomerCard({ guid, customerId, companyId }): Observable<any> {
		const url = `${this.servicesUrl}/${customerId}/update-customer-card?companyId=${companyId}`;
		return this.http.post(url, { guid });
	}
}
