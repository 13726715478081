import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from '../cache/cache.service';

@Injectable({ providedIn: 'root' })
export class ReportsService {
	private servicesUrl: string = environment.adminEndPoint;
	private paymentsUrl: string = environment.paymentsEndPoint;
	private headers = {
		headers: {
			Authorization: this.cacheService.get('accessToken') || '',
			idToken: this.cacheService.get('idToken') || '',
			'x-api-key': environment.apiKey,
		},
	};
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private readonly http: HttpClient, private readonly cacheService: CacheService) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCreditCard
	 **-------------------------------------------------------------------------------------
	 */
	public getCompanyPaymentReport(data): Observable<any> {
		const url = `${this.paymentsUrl}/reports/company/payment-report`;
		return this.http.post(
			url,
			{ ...data },
			{
				...this.headers,
			}
		);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCreditCard
	 **-------------------------------------------------------------------------------------
	 */
	public getCompanyDispersalReport(): Observable<any> {
		const url = `${this.paymentsUrl}/reports/company/dispersement-report`;
		return this.http.post(
			url,
			{},
			{
				...this.headers,
			}
		);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCreditCard
	 **-------------------------------------------------------------------------------------
	 */
	public getCompanyClosedDispersalReport(data): Observable<any> {
		const url = `${this.paymentsUrl}/reports/company/closed-dispersement-report`;
		return this.http.post(
			url,
			{
				...data,
			},
			{
				...this.headers,
			}
		);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getCompanyPendingRefundsReport
	 **-------------------------------------------------------------------------------------
	 */
	public getCompanyPendingRefundsReport(): Observable<any> {
		const url = `${this.paymentsUrl}/reports/company/pending-refunds-report`;
		return this.http.post(
			url,
			{},
			{
				...this.headers,
			}
		);
	}
}
