import AES from 'crypto-js/aes';
import encUTF8 from 'crypto-js/enc-utf8';
import { environment } from 'environments/environment';
import ls from 'localstorage-slim';
import { CookieService } from 'ngx-cookie-service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { Inject, Injectable } from '@angular/core';

ls.config.encrypt = environment.env === 'prod'; // global encryption
ls.config.secret = 'IaHKhBS#YnIo553pvQGadR4m4BrDLXYl0i'; // global secret
ls.config.ttl = 3600; //1 hour
const encrypter = (data, secret) => AES.encrypt(JSON.stringify(data), secret).toString();
const decrypter = (data, secret) => {
	try {
		return JSON.parse(AES.decrypt(data, secret).toString(encUTF8));
	} catch (e) {
		return data;
	}
};
ls.config.encrypter = encrypter;

ls.config.decrypter = decrypter;

const STORAGE_KEY = 'vquiprentals';
@Injectable({ providedIn: 'root' })
export class CacheService {
	private cache = {};
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		@Inject(LOCAL_STORAGE) private storage: StorageService,
		private cookieService: CookieService
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - set
	 **-------------------------------------------------------------------------------------
	 */
	public set(key: string, value: any): void {
		this.cookieService.set(key, value, 1);
		if (ls.config.encrypt) {
			ls.set(key, value);
			return;
		}
		this.storage.set(key, value);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - get
	 **-------------------------------------------------------------------------------------
	 */
	public get(key: string, defaultValue: any = ''): any {
		if (ls.config.encrypt) return decrypter(ls.get(key), ls.config.secret) || defaultValue;
		return this.storage.get(key) || defaultValue;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - delete
	 **-------------------------------------------------------------------------------------
	 */
	public delete(key: string): void {
		if (ls.config.encrypt) {
			ls.remove(key);
			return;
		}
		this.storage.remove(key);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - clear
	 **-------------------------------------------------------------------------------------
	 */
	public clear(): void {
		this.cookieService.deleteAll();
		if (ls.config.encrypt) {
			ls.clear();
			return;
		}
		this.storage.clear();
	}
}
