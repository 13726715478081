import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-alert',
	template: `
		<div *ngIf="!hide" [class]="'rounded-md bg-' + color + '-50 p-4 my-2'">
			<div class="flex">
				<div class="flex-shrink-0">
					<!-- Heroicon name: solid/check-circle -->
					<svg
						[class]="'h-5 w-5 text-' + color + '-400'"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
							clip-rule="evenodd"
						/>
					</svg>
				</div>
				<div class="ml-3">
					<p class="text-base font-medium text-green-800" [innerHTML]="message"></p>
				</div>
				<div class="ml-auto pl-3">
					<div class="-mx-1.5 -my-1.5">
						<button
							(click)="hideModal()"
							type="button"
							[class]="
								'inline-flex bg-' +
								color +
								'-50 rounded-md p-1.5 text-' +
								color +
								'-500 hover:bg-' +
								color +
								'-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600'
							"
						>
							<span class="sr-only">Dismiss</span>
							<!-- Heroicon name: solid/x -->
							<svg
								class="h-5 w-5"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fill-rule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clip-rule="evenodd"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
		</div>
	`,
})
export class AlertComponent implements OnInit {
	@Input() hide: boolean = false;
	@Input() message: string = '';
	@Input() color: string = 'red';
	@Output() onMessageCleared: EventEmitter<any> = new EventEmitter();
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor() {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	ngOnInit() {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - hideModal
	 **-------------------------------------------------------------------------------------
	 */
	public hideModal(): void {
		this.hide = true;
		this.onMessageCleared.emit();
	}
}
