import { CacheService } from 'app/shared/services/cache/cache.service';

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

@Component({
	selector: 'alert-modal-page',
	template: `
		<div class="relative border flex flex-col h-screen">
			<div class="mx-auto p-5">
				<h1 class="text-2xl font-semibold">Fraud Warning</h1>
				<div *ngIf="!showDetailedFraud" style="overflow:scroll; height:70vh">
					<p
						class="mt-4 max-w-3xl mx-auto  text-base gt-xs:text-xl text-black"
						style="overflow:scroll; height:70vh"
					>
						By checking this box, you hereby consent, acknowledge and agree that your
						electronic signature is intended to authenticate and to execute this
						agreement as well as any other notices, acknowledgements or other documents
						that you sign in connection with the transactions between us, and that each
						electronic signature will have the same force and effect as an original
						signature on paper . You have the right not to check the box and request
						that you manually sign a paper copy instead. By checking this box, you are
						waiving that right. You may, following your execution of the document(s)
						upon written request to us, obtain a single paper copy of the electronic
						record(s). No fee will be charged for any such copies, and no special
						hardware or software is required to view the electronic or digital copies.
						Your agreement and consent to use an electronic signature with us for any
						documents will continue until such time as you notify us in writing that you
						no longer wish to use an electronic signature. There is no penalty for
						withdrawing your consent. You should always make sure that we have a current
						email address in order to contact you regarding any changes, if necessary.
						Without limiting the foregoing, your electronic signature evidences your
						agreement to the terms of the rental agreement, waiver, acknowledgements and
						completion of logged safety briefing steps. If you need to update your email
						adress or would like a paper copy of the electronic records, please contact
						<br /><br />
						<strong>{{ company.companyName }}</strong
						><br />{{ company.address }}
					</p>
				</div>
				<div *ngIf="showDetailedFraud" style="overflow:scroll; height:70vh">
					<p class="my-4">
						<strong>i. FRAUD WARNINGS NOTICE TO APPLICANTS:</strong> ANY PERSON WHO
						KNOWINGLY AND WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR OTHER PERSON
						FILES AN APPLICATION FOR INSURANCE OR STATEMENT OF CLAIM CONTAINING ANY
						MATERIALLY FALSE INFORMATION OR, CONCEALS, FOR THE PURPOSE OF MISLEADING,
						INFORMATION CONCERNING ANY FACT MATERIAL THERETO, COMMITS A FRAUDULENT ACT,
						WHICH IS A CRIME AND MAY SUBJECT SUCH PERSON TO CRIMINAL AND CIVIL
						PENALTIES.
					</p>

					<p class="my-4">
						<strong>ii. NOTICE TO ALABAMA APPLICANTS: </strong> ANY PERSON WHO KNOWINGLY
						PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR WHO
						KNOWINGLY PRESENTS FALSE INFORMATION IN AN APPLICATION FOR INSURANCE IS
						GUILTY OF A CRIME AND MAY BE SUBJECT TO RESTITUTION FINES OR CONFINEMENT IN
						PRISON, OR ANY COMBINATION THEREOF.
					</p>

					<p class="my-4">
						<strong>
						iii. NOTICE TO ARKANSAS, NEW MEXICO AND WEST VIRGINIA APPLICANTS: </strong>
						 ANY PERSON WHO KNOWINGLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR
						PAYMENT OF A LOSS OR BENEFIT, OR KNOWINGLY PRESENTS FALSE INFORMATION IN AN
						APPLICATION FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO FINES
						AND CONFINEMENT IN PRISON. NOTICE TO COLORADO APPLICANTS: IT IS UNLAWFUL TO
						KNOWINGLY PROVIDE FALSE, INCOMPLETE, OR MISLEADING FACTS OR INFORMATION TO
						AN INSURANCE COMPANY FOR THE PURPOSE OF DEFRAUDING OR ATTEMPTING TO DEFRAUD
						THE COMPANY. PENALTIES MAY INCLUDE IMPRISONMENT, FINES, DENIAL OF INSURANCE,
						AND CIVIL DAMAGES. ANY INSURANCE COMPANY OR AGENT OF AN INSURANCE COMPANY
						WHO KNOWINGLY PROVIDES FALSE, INCOMPLETE, OR MISLEADING FACTS OR INFORMATION
						TO A POLICYHOLDER OR CLAIMANT FOR THE PURPOSE OF DEFRAUDING OR ATTEMPTING TO
						DEFRAUD THE POLICYHOLDER OR CLAIMANT WITH REGARD TO A SETTLEMENT OR AWARD
						PAYABLE FROM INSURANCE PROCEEDS SHALL BE REPORTED TO THE COLORADO DIVISION
						OF INSURANCE WITHIN THE DEPARTMENT OF REGULATORY AUTHORITIES. NOTICE TO
						DISTRICT OF COLUMBIA APPLICANTS: WARNING: IT IS A CRIME TO PROVIDE FALSE OR
						MISLEADING INFORMATION TO AN INSURER FOR THE PURPOSE OF DEFRAUDING THE
						INSURER OR ANY OTHER PERSON. PENALTIES INCLUDE IMPRISONMENT AND/OR FINES. IN
						ADDITION, AN INSURER MAY DENY INSURANCE BENEFITS IF FALSE INFORMATION
						MATERIALLY RELATED TO A CLAIM WAS PROVIDED BY THE APPLICANT.
					</p>

					<p class="my-4">
						<strong>iv. NOTICE TO FLORIDA APPLICANTS: </strong> ANY PERSON WHO KNOWINGLY AND
						WITH INTENT TO INJURE, DEFRAUD, OR DECEIVE ANY INSURER FILES A STATEMENT OF
						CLAIM OR AN APPLICATION CONTAINING ANY FALSE, INCOMPLETE OR MISLEADING
						INFORMATION IS GUILTY OF A FELONY OF THE THIRD DEGREE.
					</p>

					<p class="my-4">
						<strong>v. NOTICE TO KANSAS APPLICANTS: </strong> ANY PERSON WHO KNOWINGLY AND
						WITH INTENT TO DEFRAUD, PRESENTS, CAUSES TO BE PRESENTED OR PREPARED WITH
						KNOWLEDGE OR BELIEF THAT IT WILL BE PRESENTED TO OR BY AN INSURER, PURPORTED
						INSURER, BROKER OR ANY AGENT THEREOF, ANY WRITTEN, ELECTRONIC, ELECTRONIC
						IMPULSE, FACSIMILE, MAGNETIC, ORAL, OR TELEPHONIC COMMUNICATION OR STATEMENT
						AS PART OF, OR IN SUPPORT OF, AN APPLICATION FOR THE ISSUANCE OF, OR THE
						RATING OF AN INSURANCE POLICY FOR PERSONAL OR COMMERCIAL INSURANCE, OR A
						CLAIM FOR PAYMENT OR OTHER BENEFIT PURSUANT TO AN INSURANCE POLICY FOR
						COMMERCIAL OR PERSONAL INSURANCE WHICH SUCH PERSON KNOWS TO CONTAIN MATERIAL
						FALSE INFORMATION CONCERNING ANY FACT MATERIAL THERETO; OR CONCEALS, FOR THE
						PURPOSE OF MISLEADING, INFORMATION CONCERNING ANY FACT MATERIAL THERETO
						COMMITS A FRAUDULENT INSURANCE ACT. NOTICE TO KENTUCKY APPLICANTS: ANY
						PERSON WHO KNOWINGLY AND WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR
						OTHER PERSON FILES AN APPLICATION FOR INSURANCE CONTAINING ANY MATERIALLY
						FALSE INFORMATION, OR CONCEALS FOR THE PURPOSE OF MISLEADING, INFORMATION
						CONCERNING ANY FACT MATERIAL THERETO, COMMITS A FRAUDULENT INSURANCE ACT,
						WHICH IS A CRIME.
					</p>

					<p class="my-4">
						<strong>vi. NOTICE TO LOUISIANA APPLICANTS: </strong> ANY PERSON WHO KNOWINGLY
						PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR
						KNOWINGLY PRESENTS FALSE INFORMATION IN AN APPLICATION FOR INSURANCE IS
						GUILTY OF A CRIME AND MAY BE SUBJECT TO FINES AND CONFINEMENT IN PRISON.
					</p>

					<p class="my-4">
						<strong>vii. NOTICE TO MAINE APPLICANTS: </strong> IT IS A CRIME TO KNOWINGLY
						PROVIDE FALSE, INCOMPLETE OR MISLEADING INFORMATION TO AN INSURANCE COMPANY
						FOR THE PURPOSE OF DEFRAUDING THE COMPANY. PENALTIES MAY INCLUDE
						IMPRISONMENT, FINES OR A DENIAL OF INSURANCE BENEFITS.
					</p>

					<p class="my-4">
						<strong>viii. NOTICE TO MARYLAND APPLICANTS: </strong> ANY PERSON WHO KNOWINGLY OR
						WILLFULLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR
						BENEFIT OR WHO KNOWINGLY OR WILLFULLY PRESENTS FALSE INFORMATION IN AN
						APPLICATION FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO FINES
						AND CONFINEMENT IN PRISON.
					</p>

					<p class="my-4">
						<strong>ix. NOTICE TO MINNESOTA APPLICANTS: </strong> A PERSON WHO FILES A CLAIM
						WITH INTENT TO DEFRAUD OR HELPS COMMIT A FRAUD AGAINST AN INSURER IS GUILTY
						OF A CRIME.
					</p>

					<p class="my-4">
						<strong>x. NOTICE TO NEW JERSEY APPLICANTS: </strong> ANY PERSON WHO INCLUDES
						ANY FALSE OR MISLEADING INFORMATION ON AN APPLICATION FOR AN INSURANCE
						POLICY IS SUBJECT TO CRIMINAL AND CIVIL PENALTIES.
					</p>

					<p class="my-4">
						<strong>xi. NOTICE TO NEW YORK APPLICANTS: </strong> ANY PERSON WHO KNOWINGLY
						AND WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR OTHER PERSON FILES AN
						APPLICATION FOR INSURANCE OR STATEMENT OF CLAIM CONTAINING ANY MATERIALLY
						FALSE INFORMATION, OR CONCEALS FOR THE PURPOSE OF MISLEADING, INFORMATION
						CONCERNING ANY FACT MATERIAL THERETO, COMMITS A FRAUDULENT INSURANCE ACT,
						WHICH IS A CRIME, AND SHALL ALSO BE SUBJECT TO A CIVIL PENALTY NOT TO EXCEED
						FIVE THOUSAND DOLLARS AND THE STATED VALUE OF THE CLAIM FOR EACH SUCH
						VIOLATION.
					</p>

					<p class="my-4">
						<strong>xii. NOTICE TO OHIO APPLICANTS: </strong> ANY PERSON WHO, WITH INTENT TO
						DEFRAUD OR KNOWING THAT HE IS FACILITATING A FRAUD AGAINST AN INSURER,
						SUBMITS AN APPLICATION OR FILES A CLAIM CONTAINING A FALSE OR DECEPTIVE
						STATEMENT IS GUILTY OF INSURANCE FRAUD. NOTICE TO OKLAHOMA APPLICANTS:
						WARNING: ANY PERSON WHO KNOWINGLY, AND WITH INTENT TO INJURE, DEFRAUD OR
						DECEIVE ANY INSURER, MAKES ANY CLAIM FOR THE PROCEEDS OF AN INSURANCE POLICY
						CONTAINING ANY FALSE, INCOMPLETE OR MISLEADING INFORMATION IS GUILTY OF A
						FELONY (365:15-1-10, 36 §3613.1).
					</p>

					<p class="my-4">
						<strong>xiii. NOTICE TO OREGON APPLICANTS: </strong> ANY PERSON WHO KNOWINGLY AND
						WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR OTHER PERSON FILES AN
						APPLICATION FOR INSURANCE OR STATEMENT OF CLAIM CONTAINING ANY MATERIALLY
						FALSE INFORMATION OR, CONCEALS, FOR THE PURPOSE OF MISLEADING, INFORMATION
						CONCERNING ANY FACT MATERIAL THERETO, MAY BE GUILTY OF A FRAUDULENT ACT,
						WHICH MAY BE A CRIME AND MAY SUBJECT SUCH PERSON TO CRIMINAL AND CIVIL
						PENALTIES.
					</p>

					<p class="my-4">
						<strong>xiv. NOTICE TO PENNSYLVANIA APPLICANTS: </strong> ANY PERSON WHO
						KNOWINGLY AND WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR OTHER PERSON
						FILES AN APPLICATION FOR INSURANCE OR STATEMENT OF CLAIM CONTAINING ANY
						MATERIALLY FALSE INFORMATION OR CONCEALS FOR THE PURPOSE OF MISLEADING,
						INFORMATION CONCERNING ANY FACT MATERIAL THERETO COMMITS A FRAUDULENT
						INSURANCE ACT, WHICH IS A CRIME AND SUBJECTS SUCH PERSON TO CRIMINAL AND
						CIVIL PENALTIES.
					</p>

					<p class="my-4">
						<strong>xv. NOTICE TO TENNESSEE, VIRGINIA AND WASHINGTON APPLICANTS: </strong>
						IT IS A CRIME TO KNOWINGLY PROVIDE FALSE, INCOMPLETE OR MISLEADING
						INFORMATION TO AN INSURANCE COMPANY FOR THE PURPOSE OF DEFRAUDING THE
						COMPANY. PENALTIES INCLUDE IMPRISONMENT, FINES AND DENIAL OF INSURANCE
						BENEFITS. NOTICE TO VERMONT APPLICANTS: ANY PERSON WHO KNOWINGLY PRESENTS A
						FALSE STATEMENT IN AN APPLICATION FOR INSURANCE MAY BE GUILTY OF A CRIMINAL
						OFFENSE AND SUBJECT TO PENALTIES UNDER STATE LAW.
					</p>
				</div>
			</div>
		</div>
		<div class="flex justify-end p-5 gap-4 absolute bottom-0 right-0">
			<button
				(click)="closeModal()"
				class="text-xl lt-md:text-base block py-1 px-6 rounded shadow bg-gradient-to-r bg-black text-white"
			>
				Cancel
			</button>
			<button
				(click)="save()"
				class="text-xl lt-md:text-base block whitespace-nowrap  py-1 px-6 rounded shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
			>
				I Agree
			</button>
		</div>
	`,
})
export class FraudModalPage implements OnInit {
	public company = this.cache.get('company');
	public btnDisabled: boolean = true;
	@Input('showDetailedFraud') showDetailedFraud: boolean = false;

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		private modalController: ModalController,
		public loadingController: LoadingController,
		private readonly cache: CacheService
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	ngOnInit() {}

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async closeModal() {
		await this.modalController.dismiss({
			type: 'cancel',
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async save() {
		await this.modalController.dismiss({
			type: 'complete',
		});
	}
}
