import { CustomerService } from 'app/shared/services/api/customer-data.service';
import { PaymentService } from 'app/shared/services/api/payment-data.service';
import { CacheService } from 'app/shared/services/cache/cache.service';
import { mergeMap } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

const formatExpirationDate = expirationDate =>
	parseInt(expirationDate.split('/').pop() + expirationDate.split('/')[0]);

@Component({
	selector: 'app-payment-form-modal',
	templateUrl: 'payment-form-modal.component.html',
})
export class PaymentFormModalComponent implements OnInit {
	@Input('message') customMessage: string = '';
	public formIsInValid: boolean = true;
	public message: string = '';
	public customer: any = [];
	private form = <any>{};
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		private modalController: ModalController,
		private paymentService: PaymentService,
		private customerService: CustomerService,
		private cache: CacheService
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	async ngOnInit() {
		const customer = this.cache.get('customer');
		this.customer = {
			address: customer.customer_user.address_1,
			state: customer.customer_user.state,
			postalCode: customer.customer_user.postal_code,
			country: customer.customer_user?.country || 'US',
		};
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - onp
	 **-------------------------------------------------------------------------------------
	 */
	public onPaymentFormCompletion({ valid, payload }): void {
		this.formIsInValid = !valid;
		this.form = payload;
	}

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async closeModal() {
		await this.modalController.dismiss();
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async save() {
		this.message = '';
		const customer = this.cache.get('customer');
		const company = this.cache.get('company');
		const payload = {
			card: {
				cardHolderName: this.form.nameOnCard,
				cardNumber: `${this.form.cardNumber}`,
				CVV2: this.form.cvv,
				expirationDate: formatExpirationDate(this.form.expirationDate),
				customer: {
					firstName: customer.customer_user.first_name,
					lastName: customer.customer_user.last_name,
					email: customer.customer_user.email.replace(/\+/gi, ''),
				},
			},
		};
		this.formIsInValid = true;
		this.paymentService
			.saveCreditCard(payload)
			.pipe(
				mergeMap(resp =>
					this.customerService.saveCustomerCard({
						guid: resp.guid,
						customerId: customer.customer_id,
						companyId: company.company_id,
					})
				)
			)
			.subscribe(
				resp => {
					this.closeModal();
				},
				err => {
					this.formIsInValid = false;
					this.message = `There was an error saving this card`;
				}
			);
	}
}
