import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { SaveModalPage } from '../save-modal/save-modal.component';

@Component({
	selector: 'app-header',
	templateUrl: 'header.component.html',
})
export class Header implements OnInit {
	@Input() showSaveBtn: boolean = false;
	@Input() step: number = 0;
	@Input() totalSteps: number = 0;
	@Input() logo: string = '';
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(public modalController: ModalController) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	async ngOnInit() {}
}
