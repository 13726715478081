import { environment } from 'environments/environment';
import { concat, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from '../cache/cache.service';

export type LogType =
	| 'Reservation'
	| 'Risk-Assessment'
	| 'Registration'
	| 'PostRegistration'
	| 'Launch'
	| 'Telematics'
	| 'EndReservation';

export interface ReservationLog {
	logType: LogType;
	reservationId?: number;
	companyId?: number;
	message: string;
	payload?: string;
	authority?: string;
}

@Injectable({ providedIn: 'root' })
export class ReservationLoggerDataService {
	private servicesUrl: string = environment.loggingEndPoint;
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private readonly http: HttpClient, private readonly cacheService: CacheService) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getEvents
	 **-------------------------------------------------------------------------------------
	 */
	public getEvents(reservationId): Observable<any> {
		const url = `${this.servicesUrl}/reservation/list/${reservationId}`;
		return this.http.get(url, {
			headers: {
				env: environment.env,
				Authorization: this.cacheService.get('accessToken') || '',
				idToken: this.cacheService.get('idToken') || '',
			},
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - logEvent
	 **-------------------------------------------------------------------------------------
	 */
	public logEvent({
		logType,
		message,
		companyId,
		reservationId,
		payload,
		authority,
	}: ReservationLog): void {
		const url = `${this.servicesUrl}/reservation`;
		this.http
			.post(
				url,
				{ logType, message, companyId, reservationId, payload, authority },
				{
					headers: {
						env: environment.env,
					},
				}
			)
			.subscribe();
		return;
	}

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - logEvent
	 **-------------------------------------------------------------------------------------
	 */
	public logEvents(events: ReservationLog[]): void {
		const url = `${this.servicesUrl}/reservation`;
		const promises = [];
		events.forEach(item => {
			promises.push(
				this.http.post(
					url,
					{ ...item },
					{
						headers: {
							env: environment.env,
						},
					}
				)
			);
		});
		concat(...promises).subscribe();
		return;
	}
}
