export const environment = {
	production: true,
	isLocalTesting: false,
	adminUrl: 'https://admin.vquiprentals.com/secure/dashboard',
	authEndPoint: 'https://api.vquiprentals.com/auth',
	paymentsEndPoint: 'https://api.vquiprentals.com/payments',
	adminEndPoint: 'https://api.vquiprentals.com/admin',
	loggingEndPoint: 'https://api.vquiprentals.com/logs',
	storageEndPoint: 'https://storage.vquiprentals.com',
	trackingEndPoint: 'https://tracking.vquiprentals.com',
	pricingEndPoint: 'https://api.vquiprentals.com/pricing',
	featuresEndPoint: 'https://api.vquiprentals.com/features',
	companyEndPoint: 'https://api.vquiprentals.com/company',
	carrierEndPoint: 'https://api.vquiprentals.com/carriers',
	productsEndPoint: 'https://api.vquiprentals.com/products',
	registrationEndPoint: 'https://api.vquiprentals.com/registration',
	reservationsEndPoint: 'https://api.vquiprentals.com/reservations',
	invoicesEndPoint: 'https://api.vquiprentals.com/invoices',
	insuranceEndPoint: 'https://api.vquiprentals.com/insurance',
	customersEndPoint: 'https://api.vquiprentals.com/customers',
	insuranceQuotesEndPoint: 'https://api.vquipinsurance.com/quotes',
	insuranceQuotesConfigEndPoint: 'https://api.vquipinsurance.com/quotes/config',
	insurancePaymentsEndPoint: 'https://api.vquipinsurance.com/payments',
	env: 'prod',
	apiKey: 'f79Jgtu3kcbZNAGX9cnx9W1DK#NFurZYdX',
	manifestUrl: 'https://manifest.vquiprentals.com/prod/public/$reservationId/manifests/product-list',
};
