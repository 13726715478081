import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from '../cache/cache.service';

@Injectable({ providedIn: 'root' })
export class CarrierDataService {
	private servicesUrl: string = environment.carrierEndPoint;

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private readonly http: HttpClient, private readonly cacheService: CacheService) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - list
	 **-------------------------------------------------------------------------------------
	 */
	public list(): Observable<any> {
		return this.http.get(`${this.servicesUrl}/list`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getById
	 **-------------------------------------------------------------------------------------
	 */
	public getById(id): Observable<any> {
		return this.http.get(`${this.servicesUrl}/${id}`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getCarrierProductTypeForm
	 **-------------------------------------------------------------------------------------
	 */
	public getCarrierProductTypeForm(companyId, productTypeId): Observable<any> {
		return this.http.get(
			`${this.servicesUrl}/public/form/${encodeURIComponent(
				productTypeId
			)}?companyId=${companyId}`
		);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getCarrierDocumentsByType
	 **-------------------------------------------------------------------------------------
	 */
	public getCarrierDocumentsByType(carrierId, productTypeId): Observable<any> {
		return this.http
			.get(
				`${this.servicesUrl}/public/documents/${encodeURIComponent(
					productTypeId
				)}?carrierId=${carrierId}`
			)
			.pipe(
				map((item: any) => {
					if (!item.length)
						return { signAtHome: {}, completeAtHome: {}, requiredDocuments: {} };
					function filter(k) {
						if (k === 'waiver' && this.waiver) return 'waiver';
						if (k === 'rental_agreement' && this.rental_agreement)
							return 'rentalAgreement';
						if (k === 'acknowledgement' && this.acknowledgement)
							return 'acknowledgement';
						if (k === 'nav_rule' && this.nav_rule) return 'navRules';
					}
					const operator = item.find(i => i.customer_type === 'main_operator');
					const additional_operator = item.find(
						i => i.customer_type === 'additional_operator'
					);
					const minor = item.find(i => i.customer_type === 'minor');
					const passenger = item.find(i => i.customer_type === 'passenger');

					const requiredDocuments = {
						operator: Object.keys(operator).map(filter.bind(operator)).filter(Boolean),
						additional_operator: Object.keys(additional_operator)
							.map(filter.bind(additional_operator))
							.filter(Boolean),
						passenger: Object.keys(passenger)
							.map(filter.bind(passenger))
							.filter(Boolean),
						minor: Object.keys(minor).map(filter.bind(minor)).filter(Boolean),
					};
					/*
						-1 - N/A
						0 - At Home
						1 - In Person
					*/
					const signAtHome = {
						operator: operator.when_to_sign < 1 ? true : false,
						additional_operator: additional_operator.when_to_sign < 1 ? true : false,
						passenger: passenger.when_to_sign < 1 ? true : false,
						minor: minor.when_to_sign < 1 ? true : false,
					};
					const completeAtHome = {
						operator: operator.when_to_complete < 1 ? true : false,
						additional_operator:
							additional_operator.when_to_complete < 1 ? true : false,
						passenger: passenger.when_to_complete < 1 ? true : false,
						minor: minor.when_to_complete < 1 ? true : false,
					};
					return { signAtHome, completeAtHome, requiredDocuments };
				})
			);
	}
}
