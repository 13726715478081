import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

@Component({
	selector: 'image-lightbox-page',
	template: `
		<div class="relative">
			<div class="relative border flex flex-col h-screen">
				<img [src]="imgSrc" />
			</div>
			<div class="p-5 gap-4 absolute top-0 right-0">
				<button
					(click)="closeModal()"
					class="text-xl lt-md:text-base block lt-md:w-full py-1 px-6 rounded shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
				>
					Close
				</button>
			</div>
		</div>
	`,
})
export class ImageLightboxPage implements OnInit {
	@Input('imgSrc') imgSrc: string = '';
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		private modalController: ModalController,
		public loadingController: LoadingController
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	ngOnInit() {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async closeModal() {
		await this.modalController.dismiss();
	}
}
