import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

@Component({
	selector: 'save-modal-page',
	template: `
		<div class="relative border flex flex-col h-screen">
			<div class="m-auto">
				<div
					class="text-center text-2xl gt-xs:text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
				>
					Save Your Progress?
				</div>
				<p
					class="px-10 mt-4 max-w-3xl mx-auto text-center text-base gt-xs:text-xl text-gray-500"
				>
					Enter your email and we’ll send you a link you can use to
					pick up where you left off.
				</p>

				<div class="mx-8">
					<div class="mt-4 relative rounded-md shadow-sm">
						<div
							class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
						>
							<!-- Heroicon name: solid/mail -->
							<svg
								class="h-5 w-5 text-gray-400"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
								/>
								<path
									d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
								/>
							</svg>
						</div>
						<input
							type="email"
							name="email"
							id="email"
							class="focus:ring-teal-500 focus:border-teal-500 block w-full pl-10 sm:text-sm border-gray-800 rounded-md h-14"
							placeholder="you@your-email.com"
						/>
					</div>
				</div>
				<!-- Next Button -->
				<div class="mt-5 w-10/12 mx-auto">
					<button
						(click)="closeModal()"
						class="text-xl block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
					>
						Save &amp; Continue
					</button>
				</div>
			</div>
			<div
				class="absolute bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
			>
				<h1 class="text-center vquip-font-heavy text-2xl border-b">
					<span
						class="text-gray-400 font-extrabold text-3xl tracking-tight"
						>V</span
					>
					<span
						class="text-transparent  bg-clip-text bg-gradient-to-r from-teal-500 to-cyan-600"
					>
						<span class=" tracking-tight text-3xl font-extrabold"
							>Q</span
						>
						<span class=" tracking-tight text-3xl font-extrabold"
							>D</span
						>
						<span class=" tracking-tight font-semibold">ealer</span>
					</span>
				</h1>
			</div>
		</div>
	`,
})
export class SaveModalPage {
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		private modalController: ModalController,
		public loadingController: LoadingController
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async closeModal() {
		const loading = await this.loadingController.create({
			message: 'Saving Quote',
			duration: 2000,
		});
		await loading.present();
		const { role, data } = await loading.onDidDismiss();
		await this.modalController.dismiss();
	}
}
