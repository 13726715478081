import { AuthDataService } from './api/auth-data.service';
import { ReportsService } from './api/reports-data.service';
import { ReservationLoggerDataService } from './api/reservation-logger-data.service';
import { CacheService } from './cache/cache.service';
import { LanguageService } from './language/language.service';

export const services: any[] = [
	AuthDataService,
	CacheService,
	ReservationLoggerDataService,
	ReportsService,
	LanguageService,
];

export * from './api/auth-data.service';
export * from './cache/cache.service';
export * from './api/reservation-logger-data.service';
export * from './api/reports-data.service';
export * from './api/carrier-data.service';
export * from './api/products-data.service';
export * from './language/language.service';
