import { InjectorService } from 'app/Injector.service';
import {
	LanguageService,
	LogType,
	ReservationLog,
	ReservationLoggerDataService,
} from 'app/shared/services';
import { FeaturesDataService } from 'app/shared/services/api/features-data.service';
import { CacheService } from 'app/shared/services/cache/cache.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import * as rrweb from 'rrweb';

import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';

export class BasePage {
	public loadingController: LoadingController;
	public modalController: ModalController;
	public toggleMode: boolean = false;
	public message: string = '';
	public logger: ReservationLoggerDataService;
	public cache: CacheService;
	public cookie: CookieService;
	public filesuffix = new Date().getTime();
	public interval;
	public location: string = '';
	public baseRouter: Router;
	public languageService: LanguageService;
	private _features: FeaturesDataService;
	private ac: ActivatedRoute;
	private baseurl: string = '';
	private logType: LogType = 'Registration';
	private events = [];
	private logEvent: ReservationLog = {
		logType: 'Registration',
		message: '',
		payload: '',
		reservationId: 0,
		companyId: 0,
	};

	set basePage(page) {
		this.baseurl = page;
	}

	get plans() {
		return [];
	}

	set plans(plans: any) {
		this.cache.set('plans', plans);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor() {
		this.loadingController = InjectorService.injector.get(LoadingController);
		this.modalController = InjectorService.injector.get(ModalController);
		this.cache = InjectorService.injector.get(CacheService);
		this.baseRouter = InjectorService.injector.get(Router);
		this.logger = InjectorService.injector.get(ReservationLoggerDataService);
		this.ac = InjectorService.injector.get(ActivatedRoute);
		this.cookie = InjectorService.injector.get(CookieService);
		this.languageService = InjectorService.injector.get(LanguageService);
		this._features = InjectorService.injector.get(FeaturesDataService);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - navigate
	 **-------------------------------------------------------------------------------------
	 */
	public navigateByUrl(url): void {
		const token = this.ac.snapshot.queryParams?.token || this.cache.get('registrationToken');
		this.baseRouter.navigateByUrl(`${url}?token=${token}`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - setLogTupe
	 **-------------------------------------------------------------------------------------
	 */
	public setLogType(type: LogType) {
		this.logType = type;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - setPlanPricing
	 **-------------------------------------------------------------------------------------
	 */
	public setPlanPricing(pBasic, pEnhanced, pPremier) {
		this.plans[0].planPrice = pBasic;
		this.plans[1].planPrice = pEnhanced;
		this.plans[2].planPrice = pPremier;
		console.log(arguments);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - setToken
	 **-------------------------------------------------------------------------------------
	 */
	public setToken() {
		return new Promise(resolve => {
			const token =
				this.ac.snapshot.queryParams?.token || this.cookie.get('registrationToken');
			if (token) {
				this.cache.clear();
				this.cache.set('registrationToken', token);
				resolve(token);
			}
			if (!token && !this.cache.get('registrationToken')) {
				this.baseRouter.navigateByUrl('/404');
				resolve(false);
				return;
			}
			resolve(this.cache.get('registrationToken'));
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - isFieldInvalid
	 **-------------------------------------------------------------------------------------
	 */
	public isFieldInvalid(form: UntypedFormGroup, field) {
		if (form && form.get(field).touched && form.get(field).hasError('required')) {
			return true;
		}
		if (form && form.get(field).touched && form.get(field).hasError('email')) {
			return true;
		}
		if (form && form.get(field).touched && form.get(field).hasError('pattern')) {
			return true;
		}
		return false;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getErrorMessage
	 **-------------------------------------------------------------------------------------
	 */
	public getErrorMessage(form, field) {
		if (form && form.get(field).touched && form.get(field).hasError('required')) {
			return 'You must enter a value';
		}
		if (form && form.get(field).hasError('email')) {
			return 'You must enter a valid email';
		}
		if (
			form &&
			(form.get(field).hasError('maxlength') || form.get(field).hasError('minlength'))
		) {
			return 'You must enter a valid pattern';
		}
		if (form && form.get(field).touched && form.get(field).hasError('pattern')) {
			return `You must enter a valid ${field.toUpperCase()}`;
		}
		if (form && field === 'isDriving') {
			return 'If you elect to purchase insurance for this trip, adding each additional operator will increase the insurance premium by $10.00.';
		}
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - presentLoading
	 **-------------------------------------------------------------------------------------
	 */
	async presentLoading() {
		const loading = await this.loadingController.create({
			message: 'Please wait...',
		});
		await loading.present();
		return loading;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getSavedFormData
	 **-------------------------------------------------------------------------------------
	 */
	public getSavedFormData({ formName, section }) {
		const form = this.cache.get(formName);
		if (!form || !form[section]) {
			this.baseRouter.navigateByUrl(this.baseurl);
			return;
		}
		return form[section];
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getSavedFormData
	 **-------------------------------------------------------------------------------------
	 */
	public getFullForm({ formName }) {
		return this.cache.get(formName);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveFormStep
	 **-------------------------------------------------------------------------------------
	 */
	public saveFormStep({ formName, section, payload }): void {
		const form = this.cache.get(formName);
		this.cache.set(formName, {
			...form,
			[section]: {
				...form[section],
				...payload,
			},
		});
		return;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - onMessageCleared
	 **-------------------------------------------------------------------------------------
	 */
	public onMessageCleared(): void {
		this.message = '';
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - log
	 **-------------------------------------------------------------------------------------
	 */
	public log(message, authority, logType: LogType = null): void {
		const { reservation_id: reservationId, company_id: companyId } =
			this.cache.get('reservation');
		this.logger.logEvent({
			logType: logType || this.logType,
			message,
			authority,
			reservationId,
			companyId,
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getLocation
	 **-------------------------------------------------------------------------------------
	 */
	getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				position => {
					var lat = position.coords.latitude;
					var long = position.coords.longitude;
					this.location = `lat=${lat}&lon=${long}`;
					this.cache.set('latitude', lat);
					this.cache.set('longitude', long);
				},
				error => {
					console.warn(error.message);
				}
			);
		} else {
			console.warn(
				`It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it.`
			);
		}
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - record
	 **-------------------------------------------------------------------------------------
	 */
	public record(type): void {
		const reservation = this.cache.get('reservation');
		const filesuffix = this.filesuffix;
		let events = [];
		rrweb.record({
			emit(event) {
				events.push(event);
			},
		});

		function save() {
			if (!events.length) return;
			const body = JSON.stringify({
				events,
				type,
				reservation,
				filesuffix,
			});
			events = [];
			fetch(`${environment.storageEndPoint}/record`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'x-api-key': environment.apiKey,
					env: environment.env,
				},
				body,
			});
		}
		this.interval = setInterval(save, 10 * 1000);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - play
	 **-------------------------------------------------------------------------------------
	 */
	public stopRecording(): void {
		console.log('stopping');
		const highestTimeoutId = setTimeout(';');
		for (let i = 0; i < highestTimeoutId; i++) clearTimeout(i);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - alterRoutes
	 **-------------------------------------------------------------------------------------
	 */
	public alterRoutes() {
		const company = this.cache.get('company');
		const devCompanies = [102, 89];
		const stageCompanies = [74];
		const prodCompanies = [62, 74, 65, 107, 123, 113];
		if (environment.env === 'dev' && devCompanies.includes(company.company_id)) {
			return true;
		}
		if (environment.env === 'stage' && stageCompanies.includes(company.company_id)) {
			return true;
		}
		if (environment.env === 'prod' && prodCompanies.includes(company.company_id)) {
			return true;
		}
		return false;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getCompanyFeaturesActive
	 **-------------------------------------------------------------------------------------
	 */
	public async getCompanyFeaturesActive(feature): Promise<boolean> {
		const promise: Promise<boolean> = new Promise(resolve => {
			const interval = setInterval(async () => {
				let reservation = this.cache.get('reservation');
				if (reservation) {
					const features = await this._features
						.getCompanyFeatures(reservation.company_id)
						.toPromise();
					if (!features && !features.featureAreas) return false;
					const foundFeature = features.featureAreas.find(
						item => item.name.toLowerCase() === feature.toLowerCase()
					);
					resolve(foundFeature && foundFeature.isActive);
					clearInterval(interval);
				}
			}, 500);
		});

		return await promise;
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getCompanyFeaturesActive
	 **-------------------------------------------------------------------------------------
	 */
	public async getCompanyFeatures(feature): Promise<any> {
		const promise: Promise<boolean> = new Promise(resolve => {
			const interval = setInterval(async () => {
				let reservation = this.cache.get('reservation');
				if (reservation) {
					const features = await this._features
						.getCompanyFeatures(reservation.company_id)
						.toPromise();
					if (!features && !features.featureAreas) return false;
					const foundFeature = features.featureAreas.find(
						item => item.name.toLowerCase() === feature.toLowerCase()
					);
					resolve(foundFeature);
					clearInterval(interval);
				}
			}, 500);
		});

		return await promise;
	}
}
