import { Injectable } from '@angular/core';

import { ProductsService } from '../api/products-data.service';
import { CacheService } from '../cache/cache.service';

const TEXT_FILE = {
	cabin: {
		operator: 'Primary Guest',
		driverLicenseHeader: 'Please Enter ID Information and Answer any Verification Questions',
		driverLicenseSubHeader: 'Drivers License of Primary Guest (Mandatory)',
		noticeModal: 'Please review your documents prior to the day of your reservation.',
		guestButton: 'Add Guest',
		primaryOperator: 'Primary Guest',
		additionalOperator: 'Additional Guest',
		launchNotice: 'All forms and signatures WILL be required on arrival.',
		finalizeManifestBold:
			'Waiver & Rental Agreement: We ask that you read these documents thoroughly.',
		finalizeManifest: '',
		equipmentCheck: 'Check In',
		readyToLaunch: 'Check-in',
	},
	house: {
		operator: 'Primary Guest',
		driverLicenseHeader: 'Please Enter ID Information and Answer any Verification Questions',
		driverLicenseSubHeader: 'Drivers License of Primary Guest (Mandatory)',
		noticeModal: 'Please review your documents prior to the day of your reservation.',
		guestButton: 'Add Guest',
		primaryOperator: 'Primary Guest',
		additionalOperator: 'Additional Guest',
		launchNotice: 'All forms and signatures WILL be required on arrival.',
		finalizeManifestBold:
			'Waiver & Rental Agreement: We ask that you read these documents thoroughly.',
		finalizeManifest: '',
		equipmentCheck: 'Check In',
		readyToLaunch: 'Check-in',
	},
	ssv: {
		finalizeManifestBold:
			'Waiver, Rental Agreement, Safety Briefing, and Safety Training Video:',
		finalizeManifes:
			'We ask that you read these documents thoroughly and watch the Safety Training Video so that you are fully prepared for your trip.',
		readyToLaunch: 'Check-out',
		equipmentCheck: 'Check Out',
	},
	snowmobile: {
		finalizeManifestBold:
			'Waiver, Rental Agreement, Safety Briefing, and Safety Training Video:',
		finalizeManifest:
			'We ask that you read these documents thoroughly and watch the Safety Training Video so that you are fully prepared for your trip.',
		readyToLaunch: 'Check-out',
		equipmentCheck: 'Check Out',
	},
	'3-wheeler': {
		finalizeManifestBold:
			'Waiver, Rental Agreement, Safety Briefing, and Safety Training Video:',
		finalizeManifest:
			'We ask that you read these documents thoroughly and watch the Safety Training Video so that you are fully prepared for your trip.',
		readyToLaunch: 'Check-out',
		equipmentCheck: 'Check Out',
	},
	'golf-cart': {
		finalizeManifestBold:
			'Waiver, Rental Agreement, Safety Briefing, and Safety Training Video:',
		finalizeManifest:
			'We ask that you read these documents thoroughly and watch the Safety Training Video so that you are fully prepared for your trip.',
		readyToLaunch: 'Check-out',
		equipmentCheck: 'Check Out',
	},
	'dirt-bike': {
		finalizeManifestBold:
			'Waiver, Rental Agreement, Safety Briefing, and Safety Training Video:',
		finalizeManifest:
			'We ask that you read these documents thoroughly and watch the Safety Training Video so that you are fully prepared for your trip.',
		readyToLaunch: 'Check-out',
		equipmentCheck: 'Check Out',
	},
	atv: {
		finalizeManifestBold:
			'Waiver, Rental Agreement, Safety Briefing, and Safety Training Video:',
		finalizeManifest:
			'We ask that you read these documents thoroughly and watch the Safety Training Video so that you are fully prepared for your trip.',
		readyToLaunch: 'Check-out',
		equipmentCheck: 'Check Out',
	},
	parasail: {
		finalizeManifestBold:
			'Waiver, Rental Agreement, Safety Briefing, and Safety Training Video:',
		finalizeManifest:
			'We ask that you read these documents thoroughly and watch the Safety Training Video so that you are fully prepared for your trip.',
		readyToLaunch: 'Check-out',
		equipmentCheck: 'Check Out',
	},
};

@Injectable({ providedIn: 'root' })
export class LanguageService {
	private productTypes = [];
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		private readonly productsService: ProductsService,
		private readonly cacheService: CacheService
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - init
	 **-------------------------------------------------------------------------------------
	 */
	public init() {
		if (!this.productTypes.length)
			this.productsService.getProductTypesList().subscribe(e => {
				this.cacheService.set('productTypes', e);
			});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getLanguage
	 **-------------------------------------------------------------------------------------
	 */
	public get(term, defaultText): string {
		if (!this.cacheService || !this.cacheService.get) return;
		const productType = this.cacheService
			.get('productTypes', [])
			?.find(e => e.product_type_id === this.cacheService.get('reservation').product_type_id);
		if (!productType) return defaultText;
		if (TEXT_FILE[productType.product_key]) {
			return TEXT_FILE[productType.product_key][term];
		}
		return defaultText;
	}
}
