import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-footer',
	templateUrl: 'footer.component.html',
})
export class Footer implements OnInit {
	@Input() showNextBtn: boolean = false;
	@Input() showPrevBtn: boolean = false;
	@Input() company: any = {
		email: 'info@vquiprentals.com',
		contact: {
			main_phone: '7044969002',
		},
	};
	@Output() onNextAction = new EventEmitter<string>();
	@Output() onPrevAction = new EventEmitter<string>();
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(public modalController: ModalController) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	async ngOnInit() {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - nextPage
	 **-------------------------------------------------------------------------------------
	 */
	public nextPage(): void {
		this.onNextAction.emit();
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - prevPage
	 **-------------------------------------------------------------------------------------
	 */
	public prevPage(): void {
		this.onPrevAction.emit();
	}
}
