import { CacheService } from 'app/shared/services/cache/cache.service';

import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

@Component({
	selector: 'alert-modal-page',
	template: `
		<div class="relative border flex flex-col h-screen">
			<div class="mx-auto p-5">
				<h1 class="text-2xl font-semibold">{{ title }}</h1>
				<p
					[innerHTML]="message"
					class="mt-4 max-w-3xl mx-auto  text-base gt-xs:text-xl text-black text-center"
				></p>
			</div>
		</div>
		<div *ngIf="showClose" class="flex justify-end p-5 gap-4">
			<button
				(click)="closeModal()"
				class="text-xl lt-md:text-base block py-1 px-6 rounded shadow bg-gradient-to-r bg-black text-white"
			>
				{{ btnText }}
			</button>
		</div>
	`,
})
export class AlertModalPage implements OnInit {
	@Input('title') title: string = '';
	@Input('message') message: string = '';
	@Input('showClose') showClose: boolean = false;
	@Input('btnText') btnText: string = '';
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		private modalController: ModalController,
		public loadingController: LoadingController,
		private readonly cache: CacheService
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	ngOnInit() {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async closeModal() {
		await this.modalController.dismiss();
	}
}
