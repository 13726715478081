import { NgEventBus } from 'ng-event-bus';
import { annotation, arrowLeft, HeroIconModule, menu } from 'ng-heroicon';
import { CookieService } from 'ngx-cookie-service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InjectorService } from './Injector.service';
import { AppHttpInterceptor, ErrorInterceptor } from './shared/interceptors';
import { SharedModule } from './shared/shared.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		SharedModule,
		BrowserAnimationsModule,
		HeroIconModule.withIcons({
			annotation,
			menu,
			arrowLeft,
		}),
		FormlyModule.forRoot({ extras: { lazyRender: true } }),
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		NgEventBus,
		{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppHttpInterceptor,
			multi: true,
		},
		CookieService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private injector: Injector) {
		InjectorService.injector = this.injector;
	}
}
