import { throwError } from 'rxjs';
import { delay, retryWhen, scan, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

const RETRY_COUNT = 5;
const TIMEOUT = 1000;
@Injectable({ providedIn: 'root' })
export class BaseService {
	constructor() {}
	handleRetry(url = '', payload = {}) {
		return retryWhen(error =>
			error.pipe(
				scan((acc, error) => {
					if (acc > RETRY_COUNT) throw error;
					return acc + 1;
				}, 1),
				delay(TIMEOUT),
				tap(() => {
					console.log('Error trying to call api endpoint');
					Sentry.captureMessage(`Error Calling ${url}`, {
						user: {},
						extra: {
							...payload,
						},
					});
				})
			)
		);
	}

	handleError(err) {
		Sentry.captureException(err, {
			level: 'fatal',
			user: {},
			extra: {
				err,
			},
		});
		return throwError(err);
	}
}
