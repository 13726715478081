import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from '../cache/cache.service';

@Injectable({ providedIn: 'root' })
export class ProductsService {
	private servicesUrl: string = environment.productsEndPoint;

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private readonly http: HttpClient, private readonly cacheService: CacheService) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getList
	 **-------------------------------------------------------------------------------------
	 */
	public getList(active: boolean = false): Observable<any> {
		const url = `${this.servicesUrl}/list?active=${+active}`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductTypes
	 **-------------------------------------------------------------------------------------
	 */
	public getProductTypeDocuments(id): Observable<any> {
		const url = `${this.servicesUrl}/types/documents/${id}`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductTypes
	 **-------------------------------------------------------------------------------------
	 */
	public createProductTypeDocument(payload): Observable<any> {
		const url = `${this.servicesUrl}/types/documents/`;
		return this.http.post(url, { ...payload });
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductTypes
	 **-------------------------------------------------------------------------------------
	 */
	public updateProductTypeDocument(payload): Observable<any> {
		const url = `${this.servicesUrl}/types/documents/`;
		return this.http.put(url, { ...payload });
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductTypes
	 **-------------------------------------------------------------------------------------
	 */
	public getProductTypeSefetyQuestions(id): Observable<any> {
		const url = `${this.servicesUrl}/types/safety-questions/${id}`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductTypes
	 **-------------------------------------------------------------------------------------
	 */
	public createSafetyQuestions(id, payload): Observable<any> {
		const url = `${this.servicesUrl}/types/safety-questions/${id}`;
		return this.http.post(url, {
			...payload,
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductTypes
	 **-------------------------------------------------------------------------------------
	 */
	public updateEquipmentQuestions(id, payload): Observable<any> {
		const url = `${this.servicesUrl}/types/equipment-questions/${id}`;
		return this.http.put(url, {
			...payload,
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductTypes
	 **-------------------------------------------------------------------------------------
	 */
	public getProductTypeEquipmentQuestions(id): Observable<any> {
		const url = `${this.servicesUrl}/types/equipment-questions/${id}`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductTypes
	 **-------------------------------------------------------------------------------------
	 */
	public createEquipmentQuestions(id, payload): Observable<any> {
		const url = `${this.servicesUrl}/types/equipment-questions/${id}`;
		return this.http.post(url, {
			...payload,
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductTypes
	 **-------------------------------------------------------------------------------------
	 */
	public updateSafetyQuestions(id, payload): Observable<any> {
		const url = `${this.servicesUrl}/types/safety-questions/${id}`;
		return this.http.put(url, {
			...payload,
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductTypes
	 **-------------------------------------------------------------------------------------
	 */
	public getProductTypes(): Observable<any> {
		const url = `${this.servicesUrl}/product-type-list`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - updateProductStatus
	 **-------------------------------------------------------------------------------------
	 */
	public updateProductStatus(productId: number, status: number): Observable<any> {
		const url = `${this.servicesUrl}/${productId}/status`;
		return this.http.post(url, {
			status: status == 0 ? 1 : 0,
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductById
	 **-------------------------------------------------------------------------------------
	 */
	public getProductById(productId: number): Observable<any> {
		const url = `${this.servicesUrl}/${productId}`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductById
	 **-------------------------------------------------------------------------------------
	 */
	public getProductTypeById(id: number): Observable<any> {
		const url = `${this.servicesUrl}/types/${id}`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductById
	 **-------------------------------------------------------------------------------------
	 */
	public getProductTypesList(): Observable<any> {
		const url = `${this.servicesUrl}/public/types/list`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductById
	 **-------------------------------------------------------------------------------------
	 */
	public getProductTypeVettingForm(companyId: number, productType: string): Observable<any> {
		const url = `${this.servicesUrl}/public/vetting-form/${encodeURIComponent(
			productType
		)}?companyId=${companyId}`;
		return this.http.get(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductById
	 **-------------------------------------------------------------------------------------
	 */
	public getCompanyDocumentsByType(companyId: number, productType: string): Observable<any> {
		const url = `${this.servicesUrl}/public/documents/${encodeURIComponent(
			productType
		)}?companyId=${companyId}`;
		return this.http.get(url).pipe(
			map((item: any) => {
				if (!item.length)
					return {
						waiver: {},
						rentalAgreement: {},
						navRules: {},
						acknowledgement: {},
					};
				return {
					waiver: {
						name: 'Waiver',
						doc: item.find(i => i.document_type === 'waiver').document || 'No Document Supplied',
					},
					rentalAgreement: {
						name: 'Rental Agreement',
						doc: item.find(i => i.document_type === 'rental_agreement').document || 'No Document Supplied',
					},
					navRules: {
						name: 'Navigation Rules',
						doc: item.find(i => i.document_type === 'navigation_rules').document || 'No Document Supplied',
					},
					acknowledgement: {
						name: 'Acknowledgement',
						doc: item.find(i => i.document_type === 'acknowledgement').document || 'No Document Supplied',
					},
				};
			})
		);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - removePhoto
	 **-------------------------------------------------------------------------------------
	 */
	public removePhoto(productId: number, photoId: number): Observable<any> {
		const url = `${this.servicesUrl}/${productId}/remove-photo/${photoId}`;
		return this.http.delete(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveProduct
	 **-------------------------------------------------------------------------------------
	 */
	public saveProduct(form): Observable<any> {
		const url = `${this.servicesUrl}/${form.product_id}`;
		return this.http.put(url, form);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveProduct
	 **-------------------------------------------------------------------------------------
	 */
	public createProduct(form): Observable<any> {
		const url = `${this.servicesUrl}/product`;
		return this.http.post(url, form);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - isProductAvailable
	 **-------------------------------------------------------------------------------------
	 */
	public isProductAvailable(productId, dateRange, reservationId: number = 0): Observable<any> {
		const url = `${this.servicesUrl}/${productId}/is-available`;
		return this.http.post(url, { dateRange, reservationId });
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - deleteProduct
	 **-------------------------------------------------------------------------------------
	 */
	public deleteProduct(productId: number): Observable<any> {
		const url = `${this.servicesUrl}/${productId}`;
		return this.http.delete(url);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - uploadProductPhoto
	 **-------------------------------------------------------------------------------------
	 */
	public uploadProductPhoto(productId: number, data): Observable<any> {
		const url = `${this.servicesUrl}/${productId}/image-upload`;
		return this.http.post(url, { data });
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - uploadProductPhoto
	 **-------------------------------------------------------------------------------------
	 */
	public uploadProductReceipt(productId: number, data): Observable<any> {
		const url = `${this.servicesUrl}/${productId}/receipt-upload`;
		return this.http.post(url, { data });
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - saveCompanyProductTypeForm
	 **-------------------------------------------------------------------------------------
	 */
	public saveCompanyProductTypeVettingForm(id, payload): Observable<any> {
		return this.http.post(`${this.servicesUrl}/vetting-form/${id}`, {
			...payload,
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - getProductTypePhotos
	 **-------------------------------------------------------------------------------------
	 */
	public getProductTypePhotos({ productTypeId }): Observable<any> {
		const url = `${this.servicesUrl}/product-type-photos/?productTypeId=${encodeURIComponent(
			productTypeId
		)}`;
		return this.http.get(url);
	}
}
