import { CacheService } from 'app/shared/services/cache/cache.service';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

@Component({
	selector: 'document-modal-page',
	templateUrl: 'document-modal.component.html',
})
export class DocumentModalPage implements OnInit {
	@Input('name') name: string;
	@Input('type') type: string;
	@Input('doc') doc: string;
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		private modalController: ModalController,
		public loadingController: LoadingController,
		private readonly cache: CacheService
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	async ngOnInit() {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async closeModal(accept) {
		await this.modalController.dismiss({
			documentAccepted: accept,
			document: this.type,
		});
	}

	public replaceTokens(document) {
		const signatureToken = "$signature$";
		const initialsToken = "$initials$";

		const signatureRegex = new RegExp(signatureToken.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
		const initialsRegex = new RegExp(initialsToken.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');

		return document.replace(signatureRegex, '____________________').replace(initialsRegex, '____________________')
	}
}
