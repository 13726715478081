import { BasePage } from 'app/pages/base.page';
import { states } from 'app/shared/utils/states';
import { Country, State } from 'country-state-city';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { REGEX_PATTERNS } from '../../constants';

@Component({
	selector: 'app-payment-form',
	templateUrl: 'payment-form.component.html',
})
export class PaymentFormComponent extends BasePage implements OnInit {
	@Output() onPaymentFormCompletion: EventEmitter<any> = new EventEmitter();
	@Input() customer: any = {};
	public form: UntypedFormGroup;
	public states = [];
	public countries = Country.getAllCountries();
	public hideAddressFields = false;

	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private readonly formBuilder: UntypedFormBuilder) {
		super();
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	async ngOnInit() {
		this.setupForm();
	}

	public setupForm() {
		this.form = this.formBuilder.group({
			cardNumber: [
				window.location.hostname == 'localhost' ? 4012000098765439 : '',
				[Validators.required, Validators.pattern(REGEX_PATTERNS.CARD_NUMBER)],
			],
			nameOnCard: [
				window.location.hostname == 'localhost' ? 'Joshua Gonzalez' : '',
				[
					Validators.required,
					Validators.minLength(2),
					Validators.pattern(REGEX_PATTERNS.NO_LEADING_SPACES),
				],
			],
			address: [
				window.location.hostname == 'localhost' ? '131 Sisson Ave' : this.customer?.address,
			],
			country: [this.customer.country],
			postalCode: [
				window.location.hostname == 'localhost' ? '85284' : this.customer?.postalCode,
			],
			state: [window.location.hostname == 'localhost' ? 'GA' : this.customer?.state],
			cvv: [
				window.location.hostname == 'localhost' ? 999 : null,
				[Validators.required, Validators.pattern(REGEX_PATTERNS.CVV)],
			],
			expirationMonth: ['01', [Validators.required]],
			expirationYear: [23, [Validators.required]],
		});
		this.states = State.getStatesOfCountry('US');

		this.form.get('country').valueChanges.subscribe(value => {
			if (value != 'US') {
				this.form.get('state').setValue('n/a');
				this.form.get('postalCode').setValue('n/a');
				this.form.get('address').setValue('n/a');
				this.hideAddressFields = true;
			} else {
				this.form.get('state').setValue(null);
				this.form.get('postalCode').setValue(null);
				this.form.get('address').setValue(null);
				this.hideAddressFields = false;
			}
		});

		this.form.statusChanges.subscribe(resp => {
			this.form.value.expirationDate = `${this.form.get('expirationMonth').value}/${
				this.form.get('expirationYear').value
			}`;
			console.log(this.form.errors);
			this.onPaymentFormCompletion.emit({
				valid: resp === 'VALID',
				payload: {
					...this.form.value,
					cvv: `${this.form.value.cvv}`,
				},
			});
		});
	}
}
