import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import {
    HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CacheService } from '../services/cache/cache.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private readonly cacheService: CacheService) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - intercept
	 **-------------------------------------------------------------------------------------
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.headers.keys().length) {
			request.headers.append('Authorization', this.cacheService.get('accessToken') || '');
			request.headers.append('idToken', this.cacheService.get('idToken') || '');
			request.headers.append('x-api-key', environment.apiKey);

			return next.handle(request);
		}
		let headers = new HttpHeaders({
			Authorization: this.cacheService.get('accessToken') || '',
			idToken: this.cacheService.get('idToken') || '',
			'x-api-key': environment.apiKey,
		});

		const req = request.clone({
			headers: headers,
		});
		return next.handle(req);
	}
}
