import { CacheService } from 'app/shared/services/cache/cache.service';

import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

@Component({
	selector: 'start-detail-modal-page',
	template: `
		<div class="relative border flex flex-col h-screen">
			<div class="mx-auto p-5">
				<h1 class="text-2xl font-semibold">You're Booked! Almost done...</h1>
				<p class="mt-4 max-w-3xl mx-auto text-left text-base gt-xs:text-xl text-black">
					Now that your trip has been reserved, please click the link below to select
					insurance and complete your mandatory form/waiver review. It is vital that this
					is completed before the day of your trip <br /><br />
					<span class="italic">* This must be completed before your arrival</span>
				</p>

				<p class="text-center">
					<a
						class="inline-flex mr-5 items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-cyan-700 bg-cyan-100 hover:bg-cyan-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
						[href]="assessmentUrl"
						>Start Risk Assessment Here</a
					>
				</p>
			</div>
		</div>
	`,
})
export class StartDetailModalPage implements OnInit {
	public assessmentUrl: string = '';
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(
		private modalController: ModalController,
		public loadingController: LoadingController,
		private readonly cache: CacheService
	) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - ngOnInit
	 **-------------------------------------------------------------------------------------
	 */
	ngOnInit() {
		this.assessmentUrl = '/risk-assessment?token=' + this.cache.get('registrationToken');
		this.cache.clear();
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - closeModal
	 **-------------------------------------------------------------------------------------
	 */
	async closeModal() {
		await this.modalController.dismiss();
	}
}
