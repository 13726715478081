import { Pipe, PipeTransform } from '@angular/core';

import { LanguageService } from '../services/language/language.service';

@Pipe({
	name: 'language',
})
export class LanguagePipe implements PipeTransform {
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - constructor
	 **-------------------------------------------------------------------------------------
	 */
	constructor(private readonly languageService: LanguageService) {}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - transform
	 **-------------------------------------------------------------------------------------
	 */
	transform(value: any, ...args: any[]): any {
		const [term] = args;
		return this.languageService.get(term, value);
	}
}
