import { CacheService } from 'app/shared/services';
import { environment } from 'environments/environment';
import { NgEventBus } from 'ng-event-bus';
import { Subject } from 'rxjs';
import { first, map, take, takeUntil } from 'rxjs/operators';
import SignaturePad from 'signature_pad';

import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import {
    AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';

@Component({
	selector: 'ng-signature-pad',
	templateUrl: 'signature-pad.component.html',
	styleUrls: ['signature-pad.component.scss'],
})
export class SignaturePadComponent implements OnInit, AfterViewInit, OnDestroy {
	private signaturePad: SignaturePad;
	public signatureDone: boolean = false;
	private unsubscribeAll: Subject<any>;

	@ViewChild('canvas', { read: ElementRef }) canvasRef: ElementRef;

	@Input() points: any[] = [];
	@Output() pointsChange = new EventEmitter<any>();

	@Input() editable = true;
	@Input() emitOnDragEnd = false;

	@Input() penColor = 'black';
	@Input() backgroundColor = 'transparent';

	@Input() showDoneButton = true;
	@Input() doneButtonText = 'Done';
	@Input() doneButtonClass = 'btn btn-primary';
	@Input() showClearButton = true;
	@Input() clearButtonText = 'Clear';
	@Input() clearButtonClass = 'btn btn-default';
	@Input() disabledDone = false;
	@Input() format: 'blob' | 'base64' | 'json' = 'blob';
	@Input() filePath: string = '';
	@Input() height = 150;
	@Input() width = 600;
	@Input() name: string;
	@Input()
	httpRequestHeaders:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };

	@Output() done = new EventEmitter();
	@Output() saveSignature = new EventEmitter();
	@Output() cleared = new EventEmitter();
	@Output() signatureStarted = new EventEmitter();

	@Input() responsive = true;

	constructor(
		private HttpClient: HttpClient,
		private readonly eventBus: NgEventBus,
		private readonly cache: CacheService
	) {
		this.unsubscribeAll = new Subject();
	}

	ngOnInit() {
		this.eventBus.on('signatureDone').subscribe((resp: any) => {
			if (this.signatureDone) return;
			if (resp.data.type == 'draw') {
				this.signatureDone = true;
				this.emitPoints();
				this.emitBlob();
				return;
			}
			this.signatureDone = true;
			this.writeSignatureToFile(resp.data.signature);
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - onDrawStart
	 *void*-------------------------------------------------------------------------------------
	 */
	public onDrawStart(): void {}
	ngAfterViewInit() {
		// Resize Canvas to full screen:
		if (this.responsive) {
			window.addEventListener('resize', () => {
				this.resizeCanvas();
			});
			this.resizeCanvas();
		}
		this.initPad();
	}
	ngOnDestroy(): void {
		this.eventBus.cast('signature');
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - writeSignatureToFile
	 **-------------------------------------------------------------------------------------
	 */
	public writeSignatureToFile(value): void {
		const c = document.getElementById('sigcanvas') as any;
		const x = c.width / 2;

		const ctx = c.getContext('2d');
		ctx.font = '50px Brush Script MT, cursive';
		ctx.textAlign = 'center';
		ctx.fillText(value, x, 75);
		c.toBlob(blob => {
			this.showClearButton = false;
			let formData = new FormData();
			formData.set('object', blob, `${this.name}.png`);
			formData.set('originating_identifier', this.filePath + `${this.name}.png`);
			this.HttpClient.post(environment.storageEndPoint + '/file', formData, {
				headers: this.httpRequestHeaders,
			}).subscribe((resp: any) => {
				console.log('typed', resp);
			});
			this.showClearButton = false;
			this.saveSignature.emit(
				`https://cdn.vquiprentals.com/${environment.env}/${this.filePath}${this.name}.png`
			);
		});
	}
	initPad() {
		this.signaturePad = new SignaturePad(this.canvasRef.nativeElement, {
			penColor: this.penColor,
			backgroundColor: this.backgroundColor,
		});
		this.signaturePad.penColor = this.penColor;

		if (this.editable) {
			this.signaturePad.on();
		} else {
			this.signaturePad.off();
		}
		this.signaturePad.onEnd = () => {
			if (this.signaturePad.isEmpty()) {
				alert('Please provide a signature.');
			} else {
				this.signatureStarted.emit({ started: true });
				console.log('i am drawing');
			}
		};

		// this.signaturePad.onEnd = () => {
		// 	this.emitPoints();
		// 	if (this.emitOnDragEnd) {
		// 		this.emitBlob();
		// 	}
		// };

		this.applyPoints();
		if (this.emitOnDragEnd) {
			this.emitBlob();
		}
	}

	clearPad() {
		this.signaturePad.clear();
		this.cleared.emit();
		this.signatureStarted.emit({ started: false });
		this.emitPoints();
		if (this.emitOnDragEnd) {
			this.emitBlob();
		}
	}

	applyPoints() {
		if (!this.points || !this.signaturePad) {
			return;
		}

		this.signaturePad.clear();
		console.log('doing stufff');

		const multiplier = this.canvasRef.nativeElement.offsetWidth / this.width;
		const points = JSON.parse(JSON.stringify(this.points));
		points.forEach(group => {
			group.points.forEach(pt => {
				pt.x = pt.x * multiplier;
				pt.y = pt.y * multiplier;
			});
		});
		this.signaturePad.fromData(points);
	}

	emitPoints() {
		console.log('doing stufff');
		const multiplier = this.canvasRef.nativeElement.offsetWidth / this.width;
		const points = JSON.parse(JSON.stringify(this.signaturePad.toData()));
		points.forEach(group => {
			group.points.forEach(pt => {
				pt.x = pt.x / multiplier;
				pt.y = pt.y / multiplier;
			});
		});
		this.pointsChange.emit(points);
	}

	emitPointsAndBlob() {
		this.signatureDone = true;

		this.emitPoints();
		this.emitBlob();
	}

	emitBlob() {
		this.done.emit();
		this.canvasRef.nativeElement.toBlob(blob => {
			this.showClearButton = false;
			let formData = new FormData();
			formData.set('object', blob, `${this.name}.png`);
			formData.set('originating_identifier', this.filePath + `${this.name}.png`);
			formData.set(
				'extras',
				JSON.stringify({
					ip_address: `${this.cache.get('ipAddress', 'Not Available')}`,
					latitude: `${this.cache.get('latitude', 'Not Available')}`,
					longitude: `${this.cache.get('longitude', 'Not Available')}`,
				})
			);
			this.HttpClient.post(environment.storageEndPoint + '/file', formData, {
				headers: this.httpRequestHeaders,
			}).subscribe((resp: any) => {
				console.log('signed', resp);
			});
			this.showClearButton = false;
			this.saveSignature.emit(
				`https://cdn.vquiprentals.com/${environment.env}/${this.filePath}${this.name}.png`
			);
		});
	}

	resizeCanvas() {
		const canvas = this.canvasRef.nativeElement;
		if (!canvas) {
			return;
		}

		const pad = canvas.closest('.signature-pad') as HTMLElement;
		if (!pad) {
			return;
		}

		const w = pad.offsetWidth;
		const h = pad.offsetWidth / (this.width / this.height);
		// canvas.setAttribute('width', `${w}`);
		// canvas.setAttribute('height', `${h}`);

		this.applyPoints();
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** METHOD NAME - completeSignature
	 **-------------------------------------------------------------------------------------
	 */
	public completeSignature(): void {
		const canvas = this.canvasRef.nativeElement;
		canvas.style.display = 'none';
		canvas.setAttribute('width', `0`);
		canvas.setAttribute('height', `0`);
	}
}
