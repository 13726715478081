import { SaveModalPage } from 'app/shared/components/save-modal/save-modal.component';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';

import { components } from './components';
import { MoreInfoModalPage } from './components/more-info-modal/more-info-modal.component';
import { StartQuoteModalPage } from './components/start-modal/start-modal.component';
import { directives } from './directives';
import { pipes } from './pipes';
import { services } from './services';

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
	],
	exports: [
		...components,
		MoreInfoModalPage,
		SaveModalPage,
		StartQuoteModalPage,
		...directives,
		...pipes,
	],
	declarations: [
		...components,
		MoreInfoModalPage,
		SaveModalPage,
		StartQuoteModalPage,
		...directives,
		...pipes,
	],
	providers: [...services, ...pipes],
})
export class SharedModule {}
